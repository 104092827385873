import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text from 'src/components/atoms/Text';
import styled from 'styled-components';

export const CenteredColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}rem` : 'unset')};
  width: 100%;
`;

export const UploadsContainer = styled(CenteredColumn)`
  gap: 4px;
  border-radius: 8px;
  border: 1px dashed #012c57;
  background: #fff;
  padding: 1rem 1.5rem;

  color: #475467;
  font-family: Inter;
  font-size: 14px;
  :hover {
    border: 1px solid ${({ theme }) => theme.redesignColor.snappt[600]};
  }
`;

export const StyledIcon = styled(Icon)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e4e7ec;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const StyledText = styled(Text)`
  line-height: 20px;
  & span {
    font-weight: bold;
    color: #007fc9;
    margin-right: 4px;
    cursor: pointer;
  }
`;

export const SubText = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

export const StyledTrigger = styled.span<{ disabled: boolean }>`
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
  pointer-events: none;
  opacity: 0.5;
  `}
`;

export const FileContainer = styled(Row)<{ error?: boolean }>`
  width: 100%;
  padding: 1rem;
  border-radius: 12px;
  border: ${({ error, theme }) =>
    error ? `2px solid ${theme.redesignColor.red[800]}` : '1px solid #e3e8ef'};
  background: #fff;

  ${({ error, theme }) =>
    error &&
    `
     & svg {
    path {
      stroke: ${theme.redesignColor.red[800]};
    }
  }
  `}

  & > div {
    flex-direction: row !important;
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: row !important;
  }
`;

export const FileTextRow = styled(Row)`
  max-width: 390px;
  @media ${({ theme }) => theme.device.mobile} {
    max-width: 275px;
  }

  & span {
    max-width: inherit;
    ${({ theme }) => theme.redesignFont.caption}
    color: ${({ theme }) => theme.redesignColor.gray[700]};
    &:first-child {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  flex: 1;
  justify-content: center;
  border-radius: 4px;
  background: ${({ theme }) => theme.redesignColor.gray[100]};
  padding: 1.5rem;

  color: ${({ theme }) => theme.redesignColor.gray[700]};
  line-height: 2rem;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat';

  & span {
    width: 100%;
    margin-bottom: 8px;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 1rem;
  }
`;

export const StyledUl = styled.ul<{ paddingInlineStart?: number | string }>`
  list-style-type: none; /* Remove default bullets */
  padding-inline-start: ${({ paddingInlineStart = 0 }) => paddingInlineStart};
  margin-block-start: 0;
  margin-block-end: 0;

  & li {
    position: relative;
  }

  & li:last-child {
    margin-bottom: 0;
  }

  & li::before {
    content: '•'; /* Bullet character */
    color: #000; /* Bullet color */
    font-size: 1.25rem; /* Bullet size */
    max-width: 10px; /* Width to create space between bullet and text */
    position: absolute; /* Position it absolutely */
    left: -1rem; /* Align it to the left of the text */
    top: 0; /* Align to the top of the li element */
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding-inline-start: 2rem;

    & li::before {
      left: -1rem; /* Align it to the left of the text */
    }
  }
`;
