import { useTranslation } from 'react-i18next';
import { DOCUMENT_TYPES } from '../features/DUP/constants';

export function useDocumentTypes() {
  const { t } = useTranslation();

  return [
    { value: DOCUMENT_TYPES[0].value, label: t('dup.documentType.paystub') }, // PAYSTUB
    { value: DOCUMENT_TYPES[1].value, label: t('dup.documentType.bankStatement') } // BANK_STATEMENT
  ];
}
