import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { App } from './App';
import { datadogInit } from './adapters/datadog';
import SnackbarProvider from './components/molecules/SnackbarProvider';
import { ErrorBoundary } from './helpers';
import './i18n/i18n';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1} autoHideDuration={2000}>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
datadogInit();
