import { KeyPrefix, Namespace, ResourceKey } from 'i18next';
import { useTranslation } from 'react-i18next';
import { WizardProofOfIncomeProps } from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { UnauthenticateSessionProofType } from 'src/types/api';
import { Instructions, StyledUl } from './styles';

export const useWizardUploadInstructions = ({
  property,
  proofType
}: Pick<WizardProofOfIncomeProps, 'property' | 'proofType'>) => {
  const { t } = useTranslation();
  const docAmt = property?.supportedDoctypes?.[proofType.toLowerCase()] || 0;
  const docType = t(getDocTypeTranslationKey(proofType, docAmt), { count: docAmt });

  const instructions = [
    t('dup.wizard.instructions.first', { count: docAmt, docType }),
    t('dup.wizard.instructions.second'),
    t('dup.wizard.instructions.third')
  ];

  const InstructionsEl = (
    <Instructions>
      <span>{t('dup.wizard.instructions.title')}:</span>
      <StyledUl>
        {instructions.map((instruction, index) => (
          <li key={`wiz-upload-instruction-${index}`}>{instruction}</li>
        ))}
      </StyledUl>
    </Instructions>
  );

  return { instructions, InstructionsEl };
};

const getDocTypeTranslationKey = (type: string, documentsAmount: number): ResourceKey => {
  const keyPrefix: KeyPrefix<Namespace> = 'dup.instructions';
  if (type === UnauthenticateSessionProofType.Paystub) {
    return `${keyPrefix}.paystub`;
  }
  if (type === UnauthenticateSessionProofType.BankStatement && Boolean(documentsAmount)) {
    return `${keyPrefix}.bankStatement`;
  }
  return `${keyPrefix}.additionalDocs`;
};
