import dup from './DUP/es.json';
import enTranslation from './en';

const TEXTS: typeof enTranslation = {
  language: {
    en: 'English',
    es: 'Spanish'
  },

  validation: {
    completeThisField: 'Completa este campo',
    countCharMax: '{{ count }} carácteres como máximo',
    shouldBeCountOrMore: 'Debe ser {{ count }} o más',
    shouldBeLessThanCountChar: 'Debe tener menos de {{ count }} carácteres',
    shouldContainCapLetter: 'Debe contener una letra mayúscula',
    shouldNotContainNumOrSym: 'No debe contener números o símbolos',
    shouldOnlyBeOneChar: 'Debería ser un solo carácter',

    phone: {
      shouldBeValid:
        'Número de teléfono no válido. Asegúrese de ingresar un teléfono válido de US.',
      shouldHave10DigitsMin: 'El número de teléfono debe tener un mínimo de 10 dígitos.',
      shouldHave15DigitsMax: 'El número de teléfono debe tener un máximo de 15 dígitos.'
    },

    email: {
      shouldBeValid: 'Dirección de correo electrónico no válida'
    }
  },

  message: {
    yes: 'Sí',
    no: 'No'
  },

  buttonLabel: {
    back: 'Regresar',
    cancel: 'Cancelar',
    edit: 'Editar',
    next: 'Siguiente',
    skip: 'Saltar Paso',
    submit: 'Enviar'
  },

  dup
};

export default TEXTS;
