import { useEffect } from 'react';
import { Loader } from 'src/components/atoms/Loader';
import WizardConnectedPayrollSection from 'src/components/DUP/molecules/WizardConnectedPayrollSection/WizardConnectedPayrollSection';
import { WizardDocumentUploadSection } from 'src/components/DUP/molecules/WizardDocumentUploadSection/WizardDocumentUploadSection';
import { PAYROLL_LINK_APPLICANT_EXPERIENCE_API_URL } from 'src/features/DUP';
import useApi from 'src/hooks/useApi';
import { FeatureFlagKeys, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { Information } from 'src/hooks/useInformation';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { Proof, UnauthenticateSessionProofType } from 'src/types/api';

export type WizardProofOfIncomeProps = DupScreenStepProps & {
  information: Information;
  proofType: UnauthenticateSessionProofType;
  proofs: Proof[];
  refresh?: () => Promise<Proof[]>;
};

export const WizardProofOfIncome = (props: WizardProofOfIncomeProps) => {
  const { property } = props;
  const {
    loading: featureFlagValueLoading,
    onGetFeatureFlagValue,
    data: isConnectedPayrollFFEnabled
  } = useFeatureFlag({
    projectName: 'fraud-platform',
    featureFlagKey: FeatureFlagKeys.connectedPayroll
  });

  const { data: payrollLinkEnabled, loading: featureLoading } = useApi<{ enabled: boolean }>({
    url: `${PAYROLL_LINK_APPLICANT_EXPERIENCE_API_URL}/${property?.id}/enabled`
  });

  useEffect(() => {
    onGetFeatureFlagValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (featureFlagValueLoading || featureLoading) {
    return <Loader isFixed />;
  }

  return (
    <div>
      {!isConnectedPayrollFFEnabled || !payrollLinkEnabled?.enabled ? (
        <WizardDocumentUploadSection {...props} />
      ) : (
        <WizardConnectedPayrollSection {...props} />
      )}
    </div>
  );
};

export default WizardProofOfIncome;
