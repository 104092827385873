import { useMemo } from 'react';
import { APPLICANT_EXPERIENCE_API_URL } from 'src/features/DUP';
import useApi, { ApiHookParams, DefaultBodyData, DefaultResponseData } from 'src/hooks/useApi';

export enum AxRoute {
  APPLICANT = 'applicant',
  CONNECTED_PAYROLL = 'payroll_link'
}

export interface AxApi<B> {
  route: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  loadsOnMount?: boolean;
  body?: B;
}

/**
 * Helper hook for making requests to the Applicant Experience API
 *
 */
function useAxApi<T = DefaultResponseData, B = DefaultBodyData>({
  route,
  method,
  loadsOnMount,
  body
}: AxApi<B>) {
  const config: ApiHookParams<B> = useMemo(
    () => ({
      url: `${APPLICANT_EXPERIENCE_API_URL}/${route}`,
      method: method,
      loadsOnMount,
      body
    }),
    [route, method, loadsOnMount, body]
  );
  return useApi<T, B>(config);
}

export default useAxApi;
