// src/contexts/SessionContext.tsx
import React, { createContext, useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Loader } from 'src/components/atoms/Loader';
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP';
import { configVars } from 'src/helpers';
import { useSessionStorage } from 'src/hooks/useSessionStorage';
import { DupApplicationType } from 'src/types/api';

export interface SessionContextProps {
  sessionToken: string | undefined;
  setSessionToken: (token: string) => void;
  validateToken: () => void;
}

const SessionContext = createContext<SessionContextProps | undefined>(undefined);

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};

interface SessionProviderProps {
  children: React.ReactNode;
  applicationType: DupApplicationType;
}

export const SessionProvider = ({ children, applicationType }: SessionProviderProps) => {
  const { companyId, propertyId } = useParams();
  const { search } = useLocation();
  const applicantIdentifier = new URLSearchParams(search).get('applicantIdentifier');
  const [sessionToken, setSessionToken] = useSessionStorage(UNAUTHENTICATED_SESSION_TOKEN_KEY, '');
  const [validateToken, setValidateToken] = React.useState<boolean>(false);
  const [loadingSession, setLoadingSession] = React.useState<boolean>(true);

  useEffect(() => {
    const validateSessionToken = async () => {
      const hostPath = configVars.applicant_experience_api_url;
      const options: RequestInit = {
        headers: {
          'content-type': 'application/json',
          'x-unauthenticated-session-token': sessionToken
        },
        method: 'GET'
      };
      let isTokenValid = false;
      try {
        const resp = await fetch(`${hostPath}/auth/validate`, options);
        if (resp.ok) {
          const result = await resp.json();
          isTokenValid = 'valid' in result && result.valid;
        }
      } catch (err) {
        console.error('Error validating session token', err);
      }
      if (!isTokenValid) {
        setSessionToken('');
      }
      setLoadingSession(false);
    };
    if (sessionToken || validateToken) {
      validateSessionToken();
      setValidateToken(false);
    }
  }, [sessionToken, setSessionToken, validateToken]);

  useEffect(() => {
    const getSessionToken = async () => {
      setLoadingSession(true);
      const hostPath = configVars.epa_url;
      const options: RequestInit = {
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          companyShortId: companyId,
          propertyShortId: propertyId,
          applicantIdentifier: applicantIdentifier ?? undefined
        })
      };
      try {
        const resp = await fetch(
          `${hostPath}/session/public?applicationType=${applicationType}`,
          options
        );
        const result = await resp.json();
        if ('token' in result) {
          setSessionToken(result.token);
        } else if ('error' in result) {
          window.location.href = `/?error=${encodeURIComponent(result.error)}`;
        }
      } catch (err) {
        console.error({ error: `${err}` });
      }
      setLoadingSession(false);
    };
    if (!sessionToken) {
      getSessionToken();
    }
  }, [sessionToken, applicationType, setSessionToken, companyId, propertyId, applicantIdentifier]);

  return (
    <SessionContext.Provider
      value={{ sessionToken, setSessionToken, validateToken: () => setValidateToken(true) }}
    >
      {loadingSession ? <Loader isFixed /> : children}
    </SessionContext.Provider>
  );
};
