import { KeyPrefix, Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FILES_TO_UPLOAD } from 'src/features/DUP/proofs/constants';
import configVars from 'src/helpers/configVars';
import { JOB_ERROR } from 'src/types/api';

export const URL_FILE_P2PDF = `${configVars.frontend_url}/assets/documents/Print_To_Pdf_Instructions.pdf`;

const PROOF_ERROR_KEYS = [
  ...JOB_ERROR,
  'FileNameLength',
  'InvalidPDF',
  'ScannedDocuments',
  'InternalServerError',
  'MaxAttempts',
  'UnknownError',
  'WordDocument'
] as const;

const useProofErrorMessages = () => {
  const { t } = useTranslation();
  const ERROR_MSG_PREFIX: KeyPrefix<Namespace> = 'dup.proof.errorMsg';

  const PROOF_ERROR_MESSAGES: Record<(typeof PROOF_ERROR_KEYS)[number], string | React.ReactNode> =
    {
      FileNameLength: t(`${ERROR_MSG_PREFIX}.FileNameLength`, {
        count: FILES_TO_UPLOAD.MAX_FILE_NAME_LENGTH
      }),
      PrintToPDFError: (
        <span>
          {t(`${ERROR_MSG_PREFIX}.PrintToPdf.part1`)}{' '}
          <a target="_blank" href={URL_FILE_P2PDF} rel="noreferrer">
            {t(`${ERROR_MSG_PREFIX}.PrintToPdf.part2`)}
          </a>{' '}
          {t(`${ERROR_MSG_PREFIX}.PrintToPdf.part3`)}
        </span>
      ),
      UnsupportedFileTypeError: t(`${ERROR_MSG_PREFIX}.UnsupportedFileTypeError`),
      UnsupportedFileNameError: t(`${ERROR_MSG_PREFIX}.UnsupportedFileNameError`),
      PdfContainsTextError: t(`${ERROR_MSG_PREFIX}.PdfContainsTextError`),
      WordDocument: t(`${ERROR_MSG_PREFIX}.WordDocument`),
      InvalidPDF: t(`${ERROR_MSG_PREFIX}.InvalidPDF`),
      UnknownError: t(`${ERROR_MSG_PREFIX}.UnknownError`),
      PageLimitError: t(`${ERROR_MSG_PREFIX}.PageLimitExceeded`),
      EncryptedPdfError: t(`${ERROR_MSG_PREFIX}.PasswordException`),
      ScannedDocuments: t(`${ERROR_MSG_PREFIX}.ScannedDocuments`),
      InternalServerError: t(`${ERROR_MSG_PREFIX}.InternalServerError`),
      MaxAttempts: t(`${ERROR_MSG_PREFIX}.MaxAttempts`),
      PDFOver25MBError: t(`${ERROR_MSG_PREFIX}.PDFOver25MBError`),
      PDFTooLargeError: t(`${ERROR_MSG_PREFIX}.PDFTooLargeError`),
      PDFParseError: t(`${ERROR_MSG_PREFIX}.PDFParseError`),
      GenericError: t(`${ERROR_MSG_PREFIX}.GenericError`)
    };

  return PROOF_ERROR_MESSAGES;
};

export default useProofErrorMessages;
