import { KeyPrefix, Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useWizardUploadInstructions } from 'src/components/DUP/molecules/WizardUpload/useWizardUploadInstructions';
import { SessionProperty, UnauthenticateSessionProofType } from 'src/types/api';

export interface WizardStepInfo {
  title: string;
  description: string;
  stepNumber: number;
}

export function useWizardSteps({ property }: { property: SessionProperty }): WizardStepInfo[] {
  const { t } = useTranslation();
  const { instructions } = useWizardUploadInstructions({
    property,
    // These instructions are for step 3, so it should match the step 3 proof type
    proofType: UnauthenticateSessionProofType.BankStatement
  });

  const keyPrefix: KeyPrefix<Namespace> = 'dup.wizard.step';

  return [
    {
      stepNumber: 1,
      title: t(`${keyPrefix}.1.title`),
      description: t(`${keyPrefix}.1.description`)
    },
    {
      stepNumber: 2,
      title: t(`${keyPrefix}.2.title`),
      description: t(`${keyPrefix}.2.description`)
    },
    {
      stepNumber: 3,
      title: t(`${keyPrefix}.3.title`),
      description: instructions[0] + t(`${keyPrefix}.3.description`)
    },
    {
      stepNumber: 4,
      title: t(`${keyPrefix}.4.title`),
      description: t(`${keyPrefix}.4.description`)
    }
  ];
}
