import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/components/atoms/Button';

import {
  ButtonsContainer,
  Container,
  HelpText
} from 'src/components/DUP/molecules/WizardProofOfIncome/styles';

import { WizardProofOfIncomeProps } from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { useWizardUpload } from 'src/components/DUP/molecules/WizardUpload/useWizardUpload';
import { HOW_TO_VIDEO_URL } from 'src/features/DUP';
import { StyledButton } from 'src/pages/DUPPage/styles';

export const WizardDocumentUploadSection = (props: WizardProofOfIncomeProps) => {
  const { name, onFinished, setActiveStep, ...uploadProps } = props;
  const { t } = useTranslation();
  const { WizardUploadEl, proofIsProcessing } = useWizardUpload(uploadProps);

  return (
    <Container>
      {WizardUploadEl}
      <HelpSection />
      <ButtonsContainer justify="space-between">
        <StyledButton
          name="back"
          onClick={() => {
            setActiveStep(name === 'step3' ? 1 : 0);
          }}
          variant={ButtonVariant.outline}
        >
          {t('buttonLabel.back')}
        </StyledButton>
        <StyledButton
          name="next"
          isDisabled={name === 'step3' && proofIsProcessing}
          onClick={onFinished}
          variant={ButtonVariant.contained}
        >
          {t('buttonLabel.next')}
        </StyledButton>
      </ButtonsContainer>
    </Container>
  );
};

export const HelpSection = () => {
  const { t } = useTranslation();

  return (
    <HelpText>
      {t('dup.wizard.helpText.part1')}
      <a href={HOW_TO_VIDEO_URL} target="_blank" rel="noreferrer">
        {t('dup.wizard.helpText.part2')}
      </a>
    </HelpText>
  );
};
