import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Loader } from 'src/components/atoms/Loader';
import { TextColor, TextVariant } from 'src/components/atoms/Text';
import {
  AccountLogoImage,
  AccountName,
  ConnectedAccount,
  ConnectedAccounts,
  DisconnectLink,
  TileContainer
} from 'src/components/DUP/molecules/WizardConnectedPayrollSection/styles';
import { useArgyleLink } from 'src/hooks/useArgyleLink';
import { StyledButton, StyledText } from 'src/pages/DUPPage/styles';
import { StyledTag } from './styles';

export const ConnectedPayrollTile = () => {
  const { t } = useTranslation();

  const {
    connectArgyle,
    accountsConnected,
    isLoadingAccounts,
    disconnectAccount,
    isDeletingAccounts
  } = useArgyleLink();

  const showLoadingIndicator = isLoadingAccounts || isDeletingAccounts;

  const connectClicked = () => {
    connectArgyle();
  };

  return (
    <>
      <TileContainer>
        {!accountsConnected?.length && <StyledTag> {t('dup.wizard.recommended')}</StyledTag>}
        <StyledText variant={TextVariant.h3} isBold={true}>
          {t('dup.wizard.connectPayroll')}
        </StyledText>
        <StyledText color={TextColor.gray500}>
          {t('dup.wizard.connectPayrollDescription')}
        </StyledText>
        {!isDeletingAccounts && !isLoadingAccounts && !!accountsConnected?.length && (
          <ConnectedAccounts>
            {accountsConnected.map((connectedAccount) => (
              <ConnectedAccount key={connectedAccount.accountId}>
                <AccountLogoImage alt={connectedAccount.name} src={connectedAccount.logoUrl} />
                <AccountName variant={TextVariant.small}>{connectedAccount.name}</AccountName>
                <DisconnectLink onClick={() => disconnectAccount(connectedAccount.accountId)}>
                  {t('dup.wizard.disconnect')}
                </DisconnectLink>
              </ConnectedAccount>
            ))}
          </ConnectedAccounts>
        )}
        <Gap height={0.5} />
        <StyledButton name="connects" onClick={connectClicked} variant={ButtonVariant.contained}>
          {t('dup.wizard.connect', { count: accountsConnected?.length || 0 })}
        </StyledButton>
      </TileContainer>
      {showLoadingIndicator && <Loader isFixed />}
    </>
  );
};

export default ConnectedPayrollTile;
