import { useTranslation } from 'react-i18next';
import { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import {
  isAdditionalDocumentProofType,
  isIncomeDocumentProofType
} from 'src/components/DUP/molecules/WizardSubmit';
import { useDocumentTypes } from 'src/hooks/useDocumentTypes';
import useProofErrorMessages from 'src/hooks/useProofErrorMessages';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { Proof, SessionApplication } from 'src/types/api';
import { Account } from 'src/types/applicantExperienceApi';
import {
  DocumentRow,
  EditButton,
  NoDocumentsContainer,
  PersonalInfoContainer,
  ReviewContainer,
  ReviewSectionContent,
  SectionContainer,
  SectionHeader,
  SectionTitle,
  StyledText
} from './styles';

const ApplicationInfo = ({
  application,
  setActiveStep,
  proofs,
  accountsConnected
}: Pick<DupScreenStepProps, 'application' | 'setActiveStep'> & {
  proofs: Proof[];
  accountsConnected: Account[] | null;
}) => {
  const { t } = useTranslation();

  const incomeDocuments = proofs.filter(isIncomeDocumentProofType);
  const additionalDocuments = proofs.filter(isAdditionalDocumentProofType);

  return (
    <ReviewContainer>
      <ReviewInfoSection
        title={t('dup.wizard.step.1.title')}
        step={1}
        setActiveStep={setActiveStep}
      >
        <PersonalInfo application={application} />
      </ReviewInfoSection>

      <ReviewInfoSection
        title={t('dup.wizard.proofOfIncome')}
        step={2}
        setActiveStep={setActiveStep}
      >
        {!!accountsConnected?.length && (
          <ConnectedPayrollAccounts accountsConnected={accountsConnected} />
        )}

        <DocumentList
          proofs={incomeDocuments}
          hasPayrollAccounts={!!accountsConnected?.length}
          isIncomeSection
        />
      </ReviewInfoSection>

      <ReviewInfoSection
        title={t('dup.wizard.step.3.title')}
        step={3}
        setActiveStep={setActiveStep}
      >
        <DocumentList
          proofs={additionalDocuments}
          hasPayrollAccounts={false} // Not needed for supporting documents section
          isIncomeSection={false}
        />
      </ReviewInfoSection>
    </ReviewContainer>
  );
};

const ReviewInfoSection = ({
  title,
  setActiveStep,
  step,
  children
}: {
  title: string;
  setActiveStep: (step: number) => void;
  step: number;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Line />
      <SectionContainer>
        <SectionHeader>
          <SectionTitle isBold>{title}</SectionTitle>
          <EditButton
            name="edit"
            variant={ButtonVariant.outline}
            color={ButtonColor.tertiary}
            onClick={() => setActiveStep(step - 1)}
          >
            {t('buttonLabel.edit')}
          </EditButton>
        </SectionHeader>
        <ReviewSectionContent>{children}</ReviewSectionContent>
      </SectionContainer>
    </>
  );
};

const PersonalInfo = ({ application }: { application: SessionApplication }) => {
  const { unit, firstName, middleInitial, lastName, phone, email } = application;
  const { t } = useTranslation();

  return (
    <PersonalInfoContainer>
      <StyledText>
        {firstName} {middleInitial} {lastName}
      </StyledText>
      {!!unit && (
        <StyledText>
          {t('dup.wizard.unit')} {unit}
        </StyledText>
      )}
      {!!phone && <StyledText>{phone}</StyledText>}
      <StyledText>{email}</StyledText>
    </PersonalInfoContainer>
  );
};

const DocumentList = ({
  proofs,
  hasPayrollAccounts,
  isIncomeSection
}: {
  proofs: Proof[];
  hasPayrollAccounts: boolean;
  isIncomeSection: boolean;
}) => {
  const { t } = useTranslation();
  const documentTypes = useDocumentTypes();
  const proofErrorMessages = useProofErrorMessages();

  // Logic for displaying appropriate message based on section and whether accounts/documents exist
  const showNoIncomeSourcesMessage = isIncomeSection && proofs.length === 0 && !hasPayrollAccounts;
  const showNoDocumentsMessage = !isIncomeSection && proofs.length === 0;

  return (
    <>
      {showNoIncomeSourcesMessage && (
        <NoDocumentsContainer>{t('dup.wizard.noIncomeSourcesUploaded')}</NoDocumentsContainer>
      )}
      {showNoDocumentsMessage && (
        <NoDocumentsContainer>{t('dup.wizard.noDocsUploaded')}</NoDocumentsContainer>
      )}
      {proofs.length > 0 && (
        <div style={{ marginTop: hasPayrollAccounts ? '16px' : '0' }}>
          {proofs.map((proof, i) => {
            const label = documentTypes.find((type) => type.value === proof.type)?.label;
            const error = proof.jobs_error?.[0];

            return (
              <DocumentRow key={proof.id} error={Boolean(error)}>
                <Icon icon="file_grey" />
                <Row wrap="wrap">
                  <span>{proof.fileName || `${label} ${proofs.length > 1 && `#${i + 1}`}`}</span>
                  {!!error && proofErrorMessages[error]}
                </Row>
              </DocumentRow>
            );
          })}
        </div>
      )}
    </>
  );
};

const ConnectedPayrollAccounts = ({ accountsConnected }: { accountsConnected: Account[] }) => {
  const { t } = useTranslation();

  return accountsConnected.length > 0 ? (
    <Row>
      <Icon icon="file_grey" />
      <StyledText>{t('dup.wizard.connectedAccounts')}:</StyledText>
      <StyledText>{accountsConnected.map((account) => account.name).join(', ')}</StyledText>
    </Row>
  ) : null;
};

export default ApplicationInfo;
