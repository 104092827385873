import { redesignColors, redesignFonts } from './constants';
import './style.scss';

const redesignTypography = {
  span: {
    fontFamily: redesignFonts.normal,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    margin: '0rem'
  },
  caption: {
    fontSize: '14px',
    lineHeight: '1.25rem',
    fontWeight: 400,
    margin: '0rem',
    color: redesignColors.black[100]
  },
  normal: {
    fontFamily: redesignFonts.normal,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: '-0.32px',
    margin: '0rem'
  },
  bold: {
    fontFamily: redesignFonts.bold
  },
  big: {
    fontFamily: redesignFonts.normal,
    fontSize: '3.375rem',
    lineHeight: '3.9375rem',
    fontWeight: 300,
    color: redesignColors.black.normal
  },
  small: {
    fontFamily: redesignFonts.normal,
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: '-0.32px',
    color: redesignColors.gray[700],
    margin: '0rem'
  },
  h1: {
    fontFamily: redesignFonts.normal,
    fontSize: '2.5rem',
    fontWeight: 400,
    color: redesignColors.black.normal,
    margin: '0rem'
  },
  h2: {
    fontFamily: redesignFonts.normal,
    fontSize: '2.125rem',
    fontWeight: 400,
    color: redesignColors.black.normal,
    margin: '0rem'
  },
  h3: {
    fontFamily: redesignFonts.normal,
    fontSize: '20px',
    fontWeight: 400,
    color: redesignColors.black.normal,
    margin: '0rem'
  },
  h4: {
    fontFamily: redesignFonts.normal,
    fontSize: '1.125rem',
    fontWeight: 400,
    color: redesignColors.black.normal,
    margin: '0rem'
  },
  colors: {
    inherit: {
      color: 'inherit'
    },
    initial: {
      color: redesignColors.black.normal
    },
    primary: {
      color: redesignColors.black.normal
    },
    secondary: {
      color: redesignColors.black.light
    },
    success: {
      color: redesignColors.green[700]
    },
    error: {
      color: redesignColors.red[700]
    },
    warning: {
      color: redesignColors.yellow[700]
    },
    info: {
      color: redesignColors.blue[700]
    },
    muted: {
      color: redesignColors.gray[300]
    },
    gray100: {
      color: redesignColors.gray[100]
    },
    gray200: {
      color: redesignColors.gray[200]
    },
    gray300: {
      color: redesignColors.gray[300]
    },
    gray500: {
      color: redesignColors.gray[500]
    },
    gray600: {
      color: redesignColors.gray[600]
    },
    gray700: {
      color: redesignColors.gray[700]
    },
    white: {
      color: redesignColors.white
    }
  }
};

export default redesignTypography;
