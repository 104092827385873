import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import { Checkbox } from 'src/components/atoms/Checkbox';
import Link, { LinkTarget } from 'src/components/atoms/Link';
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';
import Text from 'src/components/atoms/Text';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import Modal, { ModalVariant } from 'src/components/organisms/Modal';
import { Information } from 'src/hooks/useInformation';
import { Container } from './styles';

export type FooterProps = {
  information: Pick<Information, 'urlTerms' | 'urlPrivacy'>;
  isSubmittable: boolean;
  proofIsProcessing: boolean;
  isProofsHasErrors: boolean;
  onSubmit: () => Promise<void>;
};

const Footer: React.FC<FooterProps> = ({
  isSubmittable,
  information,
  proofIsProcessing,
  isProofsHasErrors,
  onSubmit
}) => {
  const { t } = useTranslation();
  const [showModalSubmitting, setShowModalSubmitting] = useState(false);
  const [isTOSChecked, setIsTOSChecked] = useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleSubmit = () => {
    setShowModalSubmitting(true);
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!isSubmitting) {
      setShowModalSubmitting(false);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (isTOSChecked && (proofIsProcessing || isProofsHasErrors)) {
      setIsTOSChecked(false);
    }
  }, [isTOSChecked, proofIsProcessing, isProofsHasErrors]);

  useEffect(() => {
    if (!isSubmittable && isTOSChecked) {
      setIsTOSChecked(false);
    }
  }, [isSubmittable, isTOSChecked]);

  const ShowCheckTerms = useCallback(() => {
    const AgreeCheck = () => {
      const config = !isSubmittable
        ? { isDisabled: !isSubmittable }
        : { isDisabled: proofIsProcessing || isProofsHasErrors };

      return (
        <Checkbox
          key={`terms_${isSubmittable}_${proofIsProcessing}_${isProofsHasErrors}`}
          onClick={(obj) => setIsTOSChecked(obj.isChecked)}
          name="tosAndPP"
          showLabel={false}
          value="yes"
          label={t('dup.submit.termsAndPrivacyAgreement.full')}
          isChecked={false}
          {...config}
        />
      );
    };

    let text = '';
    if (!isSubmittable || isProofsHasErrors || proofIsProcessing) {
      if (!isSubmittable) {
        text = t('dup.submit.incompleteForm');
      }

      if (isProofsHasErrors || proofIsProcessing) {
        text = isProofsHasErrors
          ? t('dup.submit.errorsInProofs')
          : t('dup.submit.proofsProcessing');
      }

      return (
        <ToolTip content={text} direction={ToolTipDirection.top}>
          {AgreeCheck()}
        </ToolTip>
      );
    }

    return AgreeCheck();
  }, [isSubmittable, isProofsHasErrors, proofIsProcessing, t]);

  const ButtonSubmit = useCallback(() => {
    const config = !isSubmittable
      ? { isDisabled: !isSubmittable, color: ButtonColor.disabled }
      : {
          isDisabled: !isTOSChecked || isProofsHasErrors || proofIsProcessing,
          color:
            !isTOSChecked || isProofsHasErrors || proofIsProcessing
              ? ButtonColor.disabled
              : ButtonColor.primary
        };

    return (
      <Button name="submit" onClick={handleSubmit} {...config} variant={ButtonVariant.contained}>
        {t('buttonLabel.submit')}
      </Button>
    );
  }, [isSubmittable, isTOSChecked, isProofsHasErrors, proofIsProcessing, t]);

  return (
    <Container>
      <Section>
        <Row className="checkboxRow">
          {ShowCheckTerms()}
          <span className="label">
            {t('dup.submit.termsAndPrivacyAgreement.part1')}
            <Link to={information.urlTerms} isExternal target={LinkTarget.blank}>
              {t('dup.submit.termsAndPrivacyAgreement.part2')}
            </Link>
            {t('dup.submit.termsAndPrivacyAgreement.part3')}
            <Link to={information.urlPrivacy} isExternal target={LinkTarget.blank}>
              {t('dup.submit.termsAndPrivacyAgreement.part4')}
            </Link>
          </span>
        </Row>
        <Row className="buttonContainer">{ButtonSubmit()}</Row>

        {showModalSubmitting && (
          <Modal
            showModal={showModalSubmitting}
            setShowModal={setShowModalSubmitting}
            title={t('dup.submit.title')}
            labelButtonCancel={t('message.no')}
            labelButtonConfirm={t('message.yes')}
            variant={ModalVariant.primary}
            onConfirm={handleConfirm}
            isBackClosable={false}
            isLoadingButtonConfirm={isSubmitting}
            isDisabledButtonConfirm={isSubmitting}
            isDisabledButtonCancel={isSubmitting}
          >
            <Text>{t('dup.submit.message')}</Text>
          </Modal>
        )}
      </Section>
    </Container>
  );
};

export default Footer;
