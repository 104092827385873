import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Check = styled.button<{ isChecked?: boolean }>`
  display: flex;
  align-items: center;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  width: fit-content;
  overflow: hidden;

  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.color.gray[300]};
    svg {
      path {
        fill: ${({ theme }) => theme.color.gray[300]};
      }
    }
  }
  .label {
    ${({ theme }) => theme.font.small};
    color: ${({ theme, isChecked }) => theme.color.gray[isChecked ? 900 : 500]};
    margin-left: 0.375rem;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .checkedAll {
    font-weight: bold;
  }
`;
