import { useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Loader } from 'src/components/atoms/Loader';
import { useSession } from 'src/context/SessionContext';
import { FeatureFlagKeys, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useResource } from 'src/hooks/useResource';
import { DUPWizard } from 'src/pages/DUPPage/DUPWizard';
import { DupApplicationType, SessionApplication } from 'src/types/api';
import { DUPPage } from './DUPPage';

export const DUPPageProvider = ({ type }: { type: DupApplicationType }) => {
  const { propertyId } = useParams();
  const { search } = useLocation();
  const applicantIdentifier = new URLSearchParams(search).get('applicantIdentifier');
  const { sessionToken, setSessionToken } = useSession();

  const {
    onGetFeatureFlagValue,
    loading,
    data: isNewDUPExperienceActiveFF
  } = useFeatureFlag({
    projectName: 'fraud-platform',
    featureFlagKey: FeatureFlagKeys.applicantDupExperienceRedesign,
    filter: `propertyId=${propertyId}`
  });

  const [application, { put, refresh }] = useResource<SessionApplication | { error: string }>(
    `/session/application`,
    { silent: true }
  );

  const getFeatureFlagValue = useCallback(() => {
    onGetFeatureFlagValue();
  }, [onGetFeatureFlagValue]);

  useEffect(() => {
    if (application) {
      if ('error' in application) {
        refresh(sessionToken);
      } else if (
        // an applicant could have applications at multiple properties, so we're just making sure we are showing the correct one
        application?.propertyShortId !== propertyId ||
        // ensuring that the application is for the correct applicant
        (applicantIdentifier && applicantIdentifier !== application.applicantDetailId)
      ) {
        setSessionToken('');
      }
    }

    if (sessionToken && isNewDUPExperienceActiveFF === null) {
      getFeatureFlagValue();
    }
  }, [
    sessionToken,
    application,
    propertyId,
    applicantIdentifier,
    isNewDUPExperienceActiveFF,
    getFeatureFlagValue,
    setSessionToken,
    refresh
  ]);

  if (
    loading ||
    !application ||
    'error' in application ||
    application?.propertyShortId !== propertyId ||
    // Don't bother loading the page if we don't have a session token yet
    Boolean(sessionToken) === false
  ) {
    return <Loader isFixed />;
  }

  const onUpdateApplication = async (updates: Partial<SessionApplication>) => {
    await put(updates);
    return await refresh(sessionToken);
  };

  return isNewDUPExperienceActiveFF && type === DupApplicationType.UNAUTHENTICATED_USER ? (
    <DUPWizard type={type} application={application} onUpdateApplication={onUpdateApplication} />
  ) : (
    <DUPPage type={type} application={application} onUpdateApplication={onUpdateApplication} />
  );
};
