import { ProofForBox } from 'src/components/DUP/molecules/BoxProof/BoxProof';
import { Icon } from 'src/components/atoms/Icon';
import { Loader } from 'src/components/atoms/Loader';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';

import useProofErrorMessages from 'src/hooks/useProofErrorMessages';
import { useResource } from 'src/hooks/useResource';
import { Loading, Thumbnail } from './styles';

export type ShowContentProps = {
  proof?: ProofForBox;
  isPolling: boolean;
};

const ShowContent: React.FC<ShowContentProps> = (props: ShowContentProps) => {
  const { proof, isPolling } = props;
  const proofErrorMessages = useProofErrorMessages();
  const [thumbnail] = useResource<{ url: string }>(
    proof?.id ? `/session/documents/${proof.id}/thumbnail` : ''
  );

  if (isPolling) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  const error = proof?.jobs_error?.[0];

  if (error) {
    return (
      <>
        <Icon icon="icon-warning" color="red" />
        <Text color={TextColor.initial} variant={TextVariant.small}>
          {proofErrorMessages[error]}
        </Text>
      </>
    );
  }

  return <Thumbnail src={thumbnail?.url} alt={`thumb_${proof?.id}`} />;
};

export default ShowContent;
