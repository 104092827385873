import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enTranslation from 'src/i18n/en';
import esTranslation from 'src/i18n/es';

export const resources = {
  en: { translation: enTranslation },
  es: { translation: esTranslation }
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    debug: false, // Set to true to see console logs
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources
  });

export default i18n;
