import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { TextColor, TextVariant } from 'src/components/atoms/Text';
import { ModalFooter } from 'src/components/DUP/molecules/ManualPayrollTile/styles';
import { TileContainer } from 'src/components/DUP/molecules/WizardConnectedPayrollSection/styles';
import { StyledUl } from 'src/components/DUP/molecules/WizardUpload/styles';
import {
  useWizardUpload,
  WizardUploadProps
} from 'src/components/DUP/molecules/WizardUpload/useWizardUpload';
import Modal, { ModalVariant } from 'src/components/organisms/Modal';
import { StyledButton, StyledText } from 'src/pages/DUPPage/styles';

export const ManualPayrollTile = ({ proofType, property, proofs, refresh }: WizardUploadProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { UploadedDocumentsSection, WizardUploadEl, instructions, proofIsProcessing } =
    useWizardUpload({
      proofType,
      proofs,
      refresh,
      property
    });

  return (
    <TileContainer>
      <StyledText variant={TextVariant.h3} isBold>
        {t('dup.wizard.manualPayroll')}
      </StyledText>
      <StyledText color={TextColor.gray500} isStretched>
        <Trans i18nKey="dup.wizard.manualPayrollDescription" />
      </StyledText>
      <StyledUl paddingInlineStart="1rem">
        {instructions.slice(1).map((instruction, index) => (
          <li key={`wiz-upload-instruction-${index}`}>
            <StyledText variant={TextVariant.small}>{instruction}</StyledText>
          </li>
        ))}
      </StyledUl>
      {UploadedDocumentsSection}
      <Gap height={0.5} />
      <StyledButton
        name="upload file"
        onClick={() => setIsModalOpen(true)}
        variant={ButtonVariant.outline}
      >
        {t('dup.wizard.uploadFile')}
      </StyledButton>
      {isModalOpen && (
        <Modal
          variant={ModalVariant.wizard}
          title={
            <StyledText variant={TextVariant.h4} isBold>
              {instructions[0]}
            </StyledText>
          }
          blurBackground
          showModal={isModalOpen}
          setShowModal={setIsModalOpen}
          showModalActions={false}
          isBackClosable={false}
          isEscapeClosable={false}
          showCloseButton
        >
          {WizardUploadEl}
          <ModalFooter>
            <StyledButton
              name="cancel upload"
              onClick={() => setIsModalOpen(false)}
              variant={ButtonVariant.outline}
            >
              {t('buttonLabel.cancel')}
            </StyledButton>
            <StyledButton
              name="next"
              isDisabled={proofIsProcessing}
              onClick={() => {
                setIsModalOpen(false);
              }}
              variant={ButtonVariant.contained}
            >
              {t('buttonLabel.next')}
            </StyledButton>
          </ModalFooter>
        </Modal>
      )}
    </TileContainer>
  );
};
