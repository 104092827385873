import { Trans, useTranslation } from 'react-i18next';
import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import Link, { LinkTarget } from 'src/components/atoms/Link';
import List, { ListItemProps, ListTypes } from 'src/components/atoms/List/List';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { HOW_TO_VIDEO_URL } from 'src/features/DUP';
import { Information } from 'src/hooks/useInformation';
import { DupApplicationType, SessionProperty } from 'src/types/api';
import { Container, TextContainer } from './styles';

export type UploadInstructionsProps = {
  type: DupApplicationType;
  instructions: Information['uploadInstruction'];
  property?: Pick<SessionProperty, 'id' | 'bankStatement' | 'paystub' | 'supportedDoctypes'>;
};

const SUPPORTED_UPLOAD_DOCTYPES = ['paystub', 'bank_statement'];

const UploadInstructions: React.FC<UploadInstructionsProps> = ({
  type,
  instructions,
  property
}: UploadInstructionsProps) => {
  const { t } = useTranslation();

  if (!property?.id) {
    return null;
  }

  const listItems: ListItemProps[] = Object.values(instructions)
    .filter((step) => !!step)
    .map((text) => ({ text }));

  return (
    <Container>
      <Row justify="space-between" alignItems="center">
        <Text variant={TextVariant.h3} color={TextColor.initial}>
          {t('dup.instructions.title')}
        </Text>
        {type !== DupApplicationType.AOA && (
          <Link to={HOW_TO_VIDEO_URL} target={LinkTarget.blank} isExternal>
            <Row gap={0.5} alignItems="center">
              <Text color="primary" isBold variant={TextVariant.small}>
                {t('dup.instructions.howTo')}
              </Text>
              <Icon icon="launch" color="primary" />
            </Row>
          </Link>
        )}
      </Row>
      <Gap height={2} />
      <TextContainer type={type}>
        <Row columns={2} gap={3}>
          <Row.Col size={1}>
            <Card border alignContent="center">
              <Icon icon="icon-cloud-upload" />
              <div className="alignLeft">
                <Instructions supportedDoctypes={property.supportedDoctypes} />
              </div>
            </Card>
          </Row.Col>
          <Row.Col size={1} className="items" data-type={type}>
            <Card background="blue">
              <List type={ListTypes.UNORDERED} items={listItems} />
            </Card>
          </Row.Col>
        </Row>
      </TextContainer>
    </Container>
  );
};

const Instructions = ({ supportedDoctypes }: { supportedDoctypes?: Record<string, number> }) => {
  const { t } = useTranslation();

  if (!supportedDoctypes) {
    return <>{t('dup.instructions.withoutAmount')}</>;
  }

  const listItems = SUPPORTED_UPLOAD_DOCTYPES.filter((d) => supportedDoctypes[d]).map((type) => {
    const count = supportedDoctypes[type];
    const docType = t(`dup.instructions.${type === 'paystub' ? 'paystub' : 'bankStatement'}`, {
      count
    });

    return (
      <li key={type}>
        <Trans i18nKey="dup.instructions.mostRecent" values={{ count, docType }} />
      </li>
    );
  });

  if (!listItems.length) {
    return <>{t('dup.instructions.withoutAmount')}</>;
  }

  return (
    <>
      {t('dup.instructions.withAmount')}
      <ul>{listItems}</ul>
    </>
  );
};

export default UploadInstructions;
