/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import useScript from 'react-script-hook';
import { PAYROLL_LINK_APPLICANT_EXPERIENCE_API_URL } from 'src/features/DUP';
import { configVars } from 'src/helpers';
import useApi from 'src/hooks/useApi';
import { Account, PayrollLinkUser } from 'src/types/applicantExperienceApi';

const DEFAULT_ARGYLE_LINK_URL = 'https://plugin.argyle.com/argyle.web.v5.js';

declare global {
  interface Window {
    Argyle: any;
  }
}

const userTokenUrl = `${PAYROLL_LINK_APPLICANT_EXPERIENCE_API_URL}/user_token`;

export const useArgyleLink = () => {
  const [loading, error] = useScript({ src: DEFAULT_ARGYLE_LINK_URL, checkForExisting: true });
  const [argyleInstance, setArgyleInstance]: any = useState(null);
  const [argyleConnectActive, setArgyleConnectActive] = useState<boolean>(false);
  const [closeArgyle, setCloseArgyle] = useState<boolean>(false);
  const [accountsConnected, setAccountsConnected] = useState<Account[] | null>(null);

  const { makeRequest: getPayrollLinkingUser, data: payrollLinkingUser } = useApi<PayrollLinkUser>({
    url: userTokenUrl,
    loadsOnMount: false
  });
  const {
    makeRequest: onGetAccountsConnected,
    data: accConnected,
    error: accountsConnectedError,
    loading: isLoadingAccounts
  } = useApi<Account[]>({
    url: `${PAYROLL_LINK_APPLICANT_EXPERIENCE_API_URL}/accounts_connected`,
    loadsOnMount: false
  });
  const {
    makeRequest: onDisconnectAccount,
    data: accountToDisconnect,
    loading: isDeletingAccounts
  } = useApi<string>({
    url: `${PAYROLL_LINK_APPLICANT_EXPERIENCE_API_URL}/account/`,
    method: 'DELETE',
    loadsOnMount: false
  });

  // Start by retrieving the linked payroll user from the API
  useEffect(() => {
    if (argyleConnectActive && !payrollLinkingUser) {
      getPayrollLinkingUser();
    }
  }, [argyleConnectActive, getPayrollLinkingUser, payrollLinkingUser]);

  const getAccountsConnected = useCallback(() => {
    onGetAccountsConnected();
  }, [onGetAccountsConnected]);

  const resetArgyle = useCallback(() => {
    setArgyleInstance(null);
    setArgyleConnectActive(false);
    getAccountsConnected();
  }, [getAccountsConnected]);

  // Once we have the linked payroll user data, we can initialize Argyle and save the user token
  useEffect(() => {
    if (!loading && payrollLinkingUser && !argyleInstance) {
      const config = {
        flowId: configVars.argyle.flow_id,
        sandbox: configVars.env !== 'production',
        onClose: () => setCloseArgyle(true)
      };
      const instance = window.Argyle.create({
        ...config,
        userToken: payrollLinkingUser.userToken,
        onClose: () => resetArgyle()
      });
      setArgyleInstance(instance);
    }
  }, [loading, payrollLinkingUser, argyleInstance, resetArgyle]);

  // Now that we know the payroll link data is available and when the connect button is clicked, we can open the Argyle link
  useEffect(() => {
    if (argyleInstance && argyleConnectActive) {
      argyleInstance.open();
    }
  }, [argyleInstance, argyleConnectActive]);

  useEffect(() => {
    //update the accounts connected state
    if (accConnected) {
      setAccountsConnected(accConnected);
    }
  }, [accConnected]);

  // We can call the accounts connected endpoint here when argyle user data is available
  useEffect(() => {
    //todo: Need to account for error state, will get with product on what they want to see for that and include in future ticket
    if (!accConnected && !accountsConnectedError) {
      getAccountsConnected();
    } else if (closeArgyle) {
      // Also call the accounts connected endpoint when the Argyle link is closed
      getAccountsConnected();
      setCloseArgyle(false);
    }
  }, [closeArgyle, accConnected, accountsConnectedError, getAccountsConnected]);

  const removeDeletedAccount = useCallback((accountId: string) => {
    setAccountsConnected(
      (prevState) =>
        prevState?.filter((accountConnected) => accountConnected.accountId !== accountId) || []
    );
  }, []);

  useEffect(() => {
    if (!isDeletingAccounts && accountToDisconnect) {
      // Remove the account from the accounts connected state after a successful delete from the API
      removeDeletedAccount(accountToDisconnect);
    }
  }, [isDeletingAccounts, accountToDisconnect, removeDeletedAccount]);

  const disconnectAccount = useCallback(
    (accountId: string) => {
      onDisconnectAccount(accountId);
    },
    [onDisconnectAccount]
  );

  return {
    loadingArgyle: loading,
    error,
    connectArgyle: () => setArgyleConnectActive(true),
    accountsConnected: accountsConnected,
    isLoadingAccounts: isLoadingAccounts,
    isDeletingAccounts: isDeletingAccounts,
    disconnectAccount
  };
};
