import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/components/atoms/Button';
import { Checkbox } from 'src/components/atoms/Checkbox';
import Link, { LinkTarget } from 'src/components/atoms/Link';
import { Loader } from 'src/components/atoms/Loader';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import ApplicationInfo from 'src/components/DUP/molecules/WizardSubmit/ApplicationInfo';
import { useArgyleLink } from 'src/hooks/useArgyleLink';
import useAxApi from 'src/hooks/useAxApi';
import { useSnackbarProvider } from 'src/hooks/useSnackbarProvider';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { StyledButton as Button } from 'src/pages/DUPPage/styles';
import { Proof } from 'src/types/api';
import { ActionContainer, Container, Terms, TermsRow } from './styles';

const getDisabledTextKey = (
  isSubmittable: boolean,
  hasErrorsInProofs: boolean,
  proofIsProcessing: boolean
) => {
  if (!isSubmittable) {
    return 'dup.submit.incompleteForm';
  }

  if (hasErrorsInProofs) {
    return 'dup.submit.errorsInProofs';
  }

  if (proofIsProcessing) {
    return 'dup.submit.proofsProcessing';
  }
  return '';
};

const WizardSubmit = (props: DupScreenStepProps & { proofs: Proof[] }) => {
  const { application, onFinished, information, setActiveStep, proofs } = props;
  const { t } = useTranslation();
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const { accountsConnected, isLoadingAccounts, isDeletingAccounts } = useArgyleLink();
  const [isTOSChecked, setIsTOSChecked] = useState(false);

  const {
    data: axData,
    makeRequest: makeAxRequest,
    loading: isSubmitting,
    error
  } = useAxApi<{ applicantId: string }, { proofs: { sourceName: string }[] }>({
    route: 'applicant/submit',
    method: 'POST',
    loadsOnMount: false,
    body: {
      proofs: proofs.map((proof) => ({
        sourceName: proof.fileName || ''
      }))
    }
  });

  const handleError = useCallback(() => {
    showSnackbar(VariantType.error, t('dup.submission.error.title'), SnackTypes.none);
  }, [showSnackbar, t, VariantType.error, SnackTypes.none]);

  useEffect(() => {
    if (error) {
      handleError();
      return;
    }
    if (axData) {
      if ('error' in axData || Boolean(error)) {
        handleError();
      } else if ('applicantId' in axData) {
        onFinished();
      }
    }
  }, [axData, onFinished, error, handleError]);

  const hasErrorsInProofs = proofs.some((proof) => Boolean(proof.jobs_error?.length));

  const isSubmittable = useMemo(() => {
    const isLoading = isLoadingAccounts || isDeletingAccounts || isSubmitting;
    const hasConnectedPayrollOrDocuments = !!accountsConnected?.length || !!proofs.length;

    return isTOSChecked && hasConnectedPayrollOrDocuments && !isLoading && !hasErrorsInProofs;
  }, [
    isLoadingAccounts,
    isDeletingAccounts,
    isSubmitting,
    accountsConnected,
    proofs,
    isTOSChecked,
    hasErrorsInProofs
  ]);

  const disableTextKey = getDisabledTextKey(isSubmittable, hasErrorsInProofs, false);

  const submitData = () => {
    if (isSubmittable) {
      makeAxRequest();
    }
  };

  return (
    <>
      <Container>
        <ApplicationInfo
          application={application}
          setActiveStep={setActiveStep}
          proofs={proofs}
          accountsConnected={accountsConnected}
        />
        <TermsRow>
          <Checkbox
            key={`terms_${isTOSChecked}_`}
            onClick={(obj) => setIsTOSChecked(obj.isChecked)}
            name="tosAndPP"
            showLabel={false}
            value="yes"
            label={t('dup.submit.termsAndPrivacyAgreement.full')}
            isChecked={isTOSChecked}
          />
          <Terms className="label">
            {t('dup.submit.termsAndPrivacyAgreement.part1')}
            <Link to={information.urlTerms} isExternal target={LinkTarget.blank}>
              {t('dup.submit.termsAndPrivacyAgreement.part2')}
            </Link>
            {t('dup.submit.termsAndPrivacyAgreement.part3')}
            <Link to={information.urlPrivacy} isExternal target={LinkTarget.blank}>
              {t('dup.submit.termsAndPrivacyAgreement.part4')}
            </Link>
          </Terms>
        </TermsRow>
        <ActionContainer justify="space-between">
          <Button
            name="back"
            onClick={() => {
              setActiveStep(2);
            }}
            variant={ButtonVariant.outline}
          >
            {t('buttonLabel.back')}
          </Button>
          <ToolTip
            direction={ToolTipDirection.top_left}
            content={disableTextKey ? t(disableTextKey) : ''}
          >
            <Button
              name="submit"
              onClick={() => submitData()}
              isDisabled={!isSubmittable}
              variant={ButtonVariant.contained}
            >
              {t('buttonLabel.submit')}
            </Button>
          </ToolTip>
        </ActionContainer>
      </Container>
      {isSubmitting && <Loader isFixed />}
    </>
  );
};

export default WizardSubmit;
