import Button, { ButtonVariant } from 'src/components/atoms/Button';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import styled from 'styled-components';

export const WizardContainer = styled.div`
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: white;
  overflow: auto;

  padding: 0.5rem 8rem;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 0.5rem;
  }
`;

export const WizardDescription = styled(Text)`
  font-size: 1rem;
  text-align: center;
  color: #012c57;
  font-family: 'PT Serif';
  font-weight: 400;
  line-height: 20px;
`;

export const StyledButton = styled(Button)`
  background: ${({ variant }) => (variant === ButtonVariant.contained ? '#008FE2' : 'unset')};
  border-radius: 48px;
  border: 0.8px solid
    ${({ variant }) => (variant === ButtonVariant.contained ? '#008FE2' : '#CDD5DF')};
  padding: 12px 32px;
  font-family: ${({ theme }) => theme.redesignFont.bold.fontFamily};
  line-height: 20px;
  font-size: 16px;
  font-weight: 700;
  color: ${({ variant }) => (variant === ButtonVariant.contained ? '#fff' : '#202939')};
  width: fit-content;
  :hover {
    background: ${({ theme, variant }) =>
      variant === ButtonVariant.contained
        ? theme.redesignColor.snappt[600]
        : theme.redesignColor.gray[50]};
    border: 0.8px solid
      ${({ variant }) => (variant === ButtonVariant.contained ? '#007FC9' : '#CDD5DF')};
    color: ${({ variant }) => (variant === ButtonVariant.contained ? '#fff' : '#202939')};
  }

  :disabled {
    background: ${({ variant }) => (variant === ButtonVariant.contained ? '#EEF2F6' : 'unset')};
    color: #9aa4b2;
    border: 0.8px solid #e3e8ef;
  }
`;

export const StyledText = styled(Text)`
  ${({ theme, variant = TextVariant.normal }) => theme.redesignFont[variant]};
  ${({ theme, color = TextColor.initial }) => theme.redesignFont.colors[color]};
  ${({ theme, isBold }) => isBold && theme.redesignFont.bold};
`;
