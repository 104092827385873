import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import { Icon, IconColor } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor } from 'src/components/atoms/Text';

export type LanguageSelectorProps = {
  iconColor?: IconColor;
  textColor?: TextColor;
  size?: 'small';
};

const LanguageSelector: React.FC<LanguageSelectorProps> = (props: LanguageSelectorProps) => {
  const { iconColor = IconColor.primary, textColor = TextColor.primary, size } = props;
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage;
  const isEnglish = language === 'en';

  const label =
    size === 'small' ? (isEnglish ? 'ES' : 'EN') : isEnglish ? t('language.es') : t('language.en');

  return (
    <Button
      name={`LanguageSelector_${language}`}
      onClick={() => i18n.changeLanguage(isEnglish ? 'es' : 'en')}
      variant={ButtonVariant.ghost}
    >
      <Row gap={0.5} alignItems="center" wrap="nowrap">
        <Text color={textColor} isBold>
          {label}
        </Text>
        <Icon icon="language" color={iconColor} />
      </Row>
    </Button>
  );
};

export default LanguageSelector;
